html.fonts100 {
  font-size: 100%;
}

body {
  * {
    font-family: 'Poppins';
  }

  iframe {
    display: none !important;
  }

  p {
    color: #000000;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
}

.easycarloan {
  &__main {
    padding-top: 40px;
  }

  .title {
    margin-bottom: 30px;
    text-align: center;
    color: #000000;
    font-weight: 700;
    font-size: 30px;
  }

  .light-line {
    color: #293969;
  }

  .bankLogo {
    padding: 0 0 60px 0;
  }

  .title-small {
    margin-bottom: 20px;
    text-align: left;
    color: #000000;
    font-weight: 700;
    font-size: 18px;
  }

  .home-page {
    background-color: #f1f4ff;
    padding: 60px 0;
    margin-bottom: 60px;

    .title {
      margin-bottom: 50px;
    }

    .item {
      text-align: center;
      padding: 0 15px;

      img {
        max-width: 150px;
        margin: 0 auto 20px auto;
      }

      h2 {
        color: #9bbb65;
        font-weight: 600;
        margin-bottom: 15px;
      }

      p {
        text-align: justify;
        text-align-last: center;
        max-width: 320px;
        margin: 0 auto;
        letter-spacing: -0.02rem;
      }
    }
  }

  .wrap-form {
    padding: 0 0 50px 0;

    .title-header {
      font-size: 50px;
      font-weight: 900;
      text-align: center;
      margin-bottom: 20px;
      position: relative;
      z-index: 1;
      color: #293969;
      font-family: 'Archivo', sans-serif;
    }

    .from {
      max-width: 450px;
      margin: 0 auto;

      .invalid-feedback {
        font-size: 0.9rem;
        font-weight: 500;
      }

      .title-form {
        text-align: center;
        font-weight: 600;
        font-size: 25px;
        margin-bottom: 25px;
        position: relative;
        z-index: 1;
      }

      .col-6,
      .col-12 {
        padding: 0;
      }

      .row {
        margin: 0;
      }

      .item-box {
        border: 1px solid #a0a0a0;
        padding: 10px 15px;
        text-align: center;
        border-radius: 6px;
        margin: 6px;
        transition: all 0.3s;
        background-color: #fff;

        &:hover,
        &.active {
          background-color: #293969;
          border-color: #293969;

          a {
            color: #ffffff;
          }
        }

        a,
        &.submit {
          display: block;
          text-decoration: none;
          transition: all 0.3s;
          font-weight: 600;
          color: #293969;
          text-transform: uppercase;
        }

        &.submit {
          cursor: pointer;
          color: #ffffff;
        }
      }
    }
  }

  .rating {
    display: flex;
    align-items: center;
    justify-content: center;

    .rate1 {
      max-width: 150px;
    }

    .rate2 {
      max-width: 140px;
      position: relative;
      top: 3px;
    }

    .rate3 {
      max-width: 100px;
      position: relative;
      top: 7px;
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 991px) {
    .wrap-content-top {
      .wrap-logo {
        padding: 20px 15px;

        .logo1 {
          img {
            max-width: 160px;
          }
        }

        .logo2 {
          img {
            max-width: 140px;
          }
        }
      }
    }

    .title {
      margin-bottom: 20px;
      font-size: 25px;
    }

    .bankLogo {
      padding: 0 0 40px 0;
    }

    .title-small {
      margin-bottom: 15px;
    }

    .home-page {
      padding: 40px 0 0 0;
      margin-bottom: 40px;

      .title {
        margin-bottom: 30px;
      }

      .item {
        padding: 0;
      }
    }

    .wrap-form {
      position: relative;

      .title-header {
        font-size: 30px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .wrap-content-top {
      .wrap-logo {
        .logo1 {
          img {
            max-width: 120px;
          }
        }

        .logo2 {
          img {
            max-width: 100px;
          }
        }
      }
    }

    .title {
      margin-bottom: 20px;
      font-size: 5.6vw;
    }

    .home-page {

      .title {
        margin-bottom: 30px;
      }

      .item {
        padding: 0 15px;
        margin-bottom: 50px;

        p {
          text-align: justify;
          text-align-last: center;
        }

        img {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.accordion {
  position: relative;
  z-index: 1;

  .accordion-item {
    border: 0;
    position: relative;
    text-align: left;
    .accordion-button {
      cursor: pointer;
      color: #293969;
      font-weight: 500;
      width: 100%;
      border: 0;
      padding: 12px 35px;
      border-radius: 13px;
      background-color: #f1f4ff;
      margin-bottom: 15px;
      text-align: left;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: relative;

      &::before,
      &::after {
        content: "";
        background-color: #9bbb65;
        position: absolute;
        width: 1em;
        height: 3px;
        right: 15px;
        top: 23px;
        border-radius: 0.03em;
      }
      &[aria-expanded="true"] {
        &::after {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .accordion-body {
    padding: 0 35px 15px 35px;
    ul,ol {
      padding: 0;
      li {
        padding-bottom: 15px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    p {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

header.header {
  background-color: #ffffff;
  position: relative;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  .maxwidth-680 {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
  }

  .max-1000 {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}

.header-wrap {
  padding: 15px 15px;
  width: 100%;
  background-color: #fff;
  display: block;

  .container {
    max-width: 1400px;
  }

  .logo {
    width: 16rem;
  }

  .secure {
    img {
      max-width: 90px;
    }
  }

  .wrapper-rating {
    position: relative;
    margin-top: -4px;
    right: -2px;
  }
}

.sub-header {
  position: relative;
  z-index: 1;

  p {
    font-size: 32px;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #9bbb65;
    padding: 4px 15px;
    margin: 0;
    line-height: 1.2;
    display: inline-block;
    font-weight: 700;
    letter-spacing: 0.02rem;
  }
}

.hr-banner {
  margin-top: -150px;
}

.wrapper-button {
  .button-link {
    width: 250px;
    height: 60px;
    border-radius: 7px;
    background-color: #9bbb65;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #293969;
    font-size: 22px;
    font-weight: 600;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      filter: drop-shadow(2px 4px 6px #293969);
    }
  }
}

.form-content {
  padding-bottom: 150px;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 500px;
    height: 15vw;
    bottom: 0;
    background: url(".././../pages/Home/assets/images/bg-content-left.png");
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
    background-image: url(".././../pages/Home/assets/images/bg-content-right.jpg");
  }
}

@media screen and (max-width: 991px) {
  .sub-header {
    p {
      font-size: 20px;
    }
  }

  .hr-banner {
    margin-top: -100px;
  }
}
.page-policy {
  .ct-inner-content {
    max-width: 1000px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 40px auto;
    * {
      font-family: "Poppins" !important;
      color: #212529;
      line-height: 1.6;
      font-size: 16px;
      font-weight: 400;
      p {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.5;
      }
      strong {
        font-weight: 600;
      }
      h1 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
        color: #212529;
        font-weight: 500;
      }
    }

    dl,
    ol,
    ul {
      margin-top: 0;
      margin-bottom: 1rem;
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      li {
        line-height: 1.5;
        margin-bottom: 5px;
      }
    }
  }
}
.footer {
  padding: 50px 0;
  background-color: #f1f4ff;

  .nav-item {
    margin-bottom: 20px;

    .nav-link {
      font-size: 15px;
      font-weight: 700;
      padding: 5px 15px;
      color: #293969;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  p,
  a {
    color: #000000;
    font-size: 12px;
    text-align: justify;
    text-align-last: center;
    line-height: 1.6;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 767px) {
  .accordion {
    .accordion-item  {
      .accordion-body {
        padding: 0 0 15px 15px;
      }

      .accordion-button {
        font-size: 18px;
        letter-spacing: -0.02rem;
        padding-left: 15px;
        border-radius: 8px;
        line-height: 1.2;
      }
    }
  }
  .form-content {
    padding-bottom: 120px;
    &::before,
    &::after {
      width: 45vw;
      height: 30vw;
    }
  }
  .easycarloan__main {
    padding-top: 20px;
  }

  .header-wrap {
    padding: 10px 0;

    .group-logo {
      padding: 0;

      .logo {
        width: 10rem;
        margin-left: -5px;
      }
    }

    .secure {
      padding-right: 0;
    }
  }

  .easycarloan .wrap-form .title-header {
    font-size: 8vw;
    margin-bottom: 10px;
  }

  .sub-header {
    p {
      font-size: 4vw;
    }
  }

  .hr-banner {
    margin-top: -40px;
    overflow: hidden;

    img {
      min-width: calc(100% + 80px);
      margin-left: -40px;
    }
  }

  .wrapper-button {
    .button-link {
      width: 200px;
      height: 50px;
      border-radius: 6px;
      font-size: 18px;
    }
  }
  .footer {
    padding: 30px 0;
    .nav-item {
      margin-bottom: 20px;
      .nav-link {
        font-size: 12px;
        padding: 5px;
      }
    }
  }
}